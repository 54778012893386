import React, { Children, ReactNode } from "react"
import { findDOMNode } from "react-dom"
import { enhancedEventKeRlog } from "../util"

export interface LogObjItem {
  name: string
  data: Record<string, string>
}

export interface LogOption {
  mount?: LogObjItem | LogObjItem[]
  unmount?: LogObjItem | LogObjItem[]
  click?: LogObjItem | LogObjItem[]
}

export class YDLog extends React.Component<
  {
    children: ReactNode
  } & LogOption
> {
  attachedClickEvent: undefined | (() => void)
  catchedDom: ReturnType<typeof findDOMNode> | undefined = undefined
  onClickProxy = () => {
    this.report(this.props.click)
  }
  componentDidMount() {
    if (this.props.mount) {
      this.report(this.props.mount)
    }
    if (this.props.click) {
      this.tryAttachClickProxy()
    }
  }
  componentDidUpdate() {
    if (this.props.click) {
      this.tryAttachClickProxy()
    }
  }
  tryAttachClickProxy() {
    // eslint-disable-next-line react/no-find-dom-node
    const dom = findDOMNode(this)
    if (dom) {
      if (
        this.attachedClickEvent &&
        this.catchedDom != null &&
        this.catchedDom !== dom
      ) {
        this.attachedClickEvent()
      }
      dom.addEventListener('click', this.onClickProxy)
      this.attachedClickEvent = () => {
        this.attachedClickEvent = undefined
        dom.removeEventListener('click', this.onClickProxy)
      }
    }
  }
  componentWillUnmount() {
    if (this.attachedClickEvent) {
      this.attachedClickEvent()
    }
    if (this.props.unmount) {
      this.report(this.props.unmount)
    }
  }
  report(logItem: LogObjItem | LogObjItem[] | undefined) {
    if (logItem == null) {
      return
    }
    if (Array.isArray(logItem)) {
      logItem.forEach((logItem) => {
        this.report(logItem)
      })
      return
    }
    enhancedEventKeRlog(logItem.name, logItem.data)
  }
  render() {
    return Children.only(this.props.children)
  }
}