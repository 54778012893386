import React from 'react'
import s from './index.module.sass'
interface AvatarProps {
  url: string;
  size?: number;
  dot?: boolean;
}

export default function Avatar(props: AvatarProps): React.ReactElement {
  const size = props.size != null ? props.size + 'px' : '100%'
  return <div className={s.avatar} style={{ width: size, height: size, backgroundImage: `url(${props.url})` }}>
    {props.dot && <div className={s.dot}></div>}
  </div>
}