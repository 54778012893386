import React, { memo } from 'react'
import { PreviewPagePath } from '../../pagerouter'
import Avatar from '../Avatar'
import Go2Personal from 'components/Go2Personal'
import { iconDelete } from 'assets'
import IfRender from 'components/IfRender'
import MessageDesc, {needLink2Person, Link2Personal} from 'components/MessageCard/MessageDesc'
import { changeEmojiDesc2Img } from 'components/Emoji'
import { escapeHTML } from 'util/index'
/** @ts-ignore */
import s from './index.module.sass'

export enum MsgType {
  LICKED = 1,
  SAVED = 2,
  COMMENT = 3,
  REPLAY = 4,
  REPORT_SUCCESS = 5,
  WARNING = 6,
  OFF = 7,
  OFF_SUCCESS = 8,
  REPORT_CR_SUCCESS = 9, //评论/回复举报成功通知
  REPORT_HANDLE = 10, //评论/回复举报处理通知：
  DELETE_COMMENT = 11, // 删评通知
  USER_REPORT = 12, // 用户举报成功通知：
  USER_REPORT_HANDLE = 13, // 用户举报处理通知
  MUTE = 14, // 禁言通知：
  MUTE_OFF = 15, // 禁言解除通知：
}

export const MSG_TEXT_MAP = {
  [MsgType.LICKED]: '点赞',
  [MsgType.SAVED]: '收藏',
  [MsgType.COMMENT]: '评论',
  [MsgType.REPLAY]: '回复',
  [MsgType.REPORT_SUCCESS]: '【收到了】',
  [MsgType.WARNING]: '【警告了】',
  [MsgType.OFF]: '【下架了】',
  [MsgType.OFF_SUCCESS]: '【下架了】',
} as any

export interface MessageCardProps {
  id: number
  worksId: number
  worksTitle: string
  actionUserId: string
  actionUserName: string
  reportedUserId?: string
  reportedUserName?: string
  type: MsgType
  haveRead: number
  createTime: string
  actionUserImage: string
  content?: string
  onClick?: (arg: Omit<MessageCardProps, 'onClick'>) => void
  onDelete?: (id: number) => void
}

export default memo(function MessageCard({
  onClick,
  ...props
}: MessageCardProps) {
  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (props.onDelete && props.id) {
      props.onDelete(props.id)
    }
  }

  const handleClick = () => {
    if (onClick) {
      onClick(props)
    }
  }

  const commentReplay = [MsgType.COMMENT, MsgType.REPLAY].includes(props.type)

  const systemMsg = [
    MsgType.REPORT_SUCCESS,
    MsgType.WARNING,
    MsgType.OFF,
    MsgType.OFF_SUCCESS,
    MsgType.REPORT_CR_SUCCESS,
    MsgType.REPORT_HANDLE,
    MsgType.DELETE_COMMENT,
    MsgType.USER_REPORT,
    MsgType.USER_REPORT_HANDLE,
    MsgType.MUTE,
    MsgType.MUTE_OFF,
  ].includes(props.type)

  const showDelBtn = commentReplay

  const showContent = commentReplay || systemMsg

  return (
    <div className={s['message-card-container']} onClick={handleClick}>
      <div className={s['avatar-ct']}>
        <Go2Personal id={props.actionUserId}>
          <Avatar url={props.actionUserImage} size={55} dot={!props.haveRead} />
        </Go2Personal>
      </div>
      <div style={{width: '100%'}}>
        <div className={s['message-card-line1']}>
          <div className={s['message-card-nickname']}>
            <Go2Personal id={props.actionUserId}>
              {props.actionUserName}
            </Go2Personal>
          </div>
          <div className={s['message-card-timestamp']}>{props.createTime}</div>
        </div>
        <div className={s['message-card-content-line']}>
          <MessageDesc type={props.type}>
            <>
              {
                needLink2Person(props.type)
                ? <Link2Personal id={props.reportedUserId!} name={props.reportedUserName!} />
                : <a target="_blank" rel="noreferrer" href={PreviewPagePath + props.worksId}>《{props.worksTitle || '未命名'}》</a>
              }
            </>
          </MessageDesc>
        </div>
        <IfRender show={showContent}>
          <div
            className={s['comment-wrap']}
            dangerouslySetInnerHTML={{
              __html: changeEmojiDesc2Img(escapeHTML(props.content || '')),
            }}
          />
        </IfRender>
      </div>
      <IfRender show={showDelBtn}>
        <img
          onClick={handleDelete}
          className={s['icon-delete']}
          src={iconDelete}
          alt=""
        />
      </IfRender>
    </div>
  )
})
