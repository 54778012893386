import React, { memo, useContext } from 'react'
/** @ts-ignore */
import { qrcodeSvg } from '../../assets'
/** @ts-ignore */
import s from './index.module.sass'
import { useYdCourseApp } from 'hooks/useYdCourseApp'
import { UserContext } from 'components/UserProvider'
import { KE_HOST, ICODE_CONTEST } from 'util/constants'

export default memo(function Footer() {
  const { user } = useContext(UserContext)
  const { isYdCourseAppWebView } = useYdCourseApp(user)
  if(isYdCourseAppWebView) return null;

  return <div className={`${s.footer} full-width`}>
    <div className="restrict-width">
      <div className={s['footer-main']}>
        <div className={s['flex-grow']}>
          <div className={s['footer-line1']}>
            友情链接&nbsp;:&nbsp;
            <a target="_blank" href="https://icode.youdao.com" rel="noreferrer">有道小图灵</a>&nbsp;&nbsp;|&nbsp;&nbsp;
            <a target="_blank" href={KE_HOST} rel="noreferrer">有道优学</a>&nbsp;&nbsp;|&nbsp;&nbsp;
            <a target="_blank" href={ICODE_CONTEST} rel="noreferrer">有道信息学活动</a>&nbsp;&nbsp;|&nbsp;&nbsp;
            <a target="_blank" href="https://c.youdao.com/icode/download.html" rel="noreferrer">小图灵客户端</a>
          </div>
          <div className={s['footer-line1']}>
            不良信息举报电话：4006666696&nbsp;|&nbsp;举报邮箱：youdao_jubao@rd.netease.com&nbsp;|&nbsp;<a href="https://www.12377.cn" target="_blank" rel="noreferrer">网上有害信息举报专区</a>
          </div>
          <div className={s['footer-line2']}>
            copyright@2021&nbsp;<a href="https://www.163.com/">网易公司</a>&nbsp;｜&nbsp;
            <a target="_blank" href="http://corp.163.com/gb/legal.html" rel="noreferrer">隐私策略</a>&nbsp;｜&nbsp;
            <a target="_blank" href="https://icode.youdao.com/tos" rel="noreferrer">服务协议</a>&nbsp;
            京ICP证080268号&nbsp;京公网安备110108003898号
          </div>
        </div>
        <div className={s['flex-none']}>
          <img src={qrcodeSvg} className={s.qrcode} />
        </div>
      </div>
    </div>
  </div>
})