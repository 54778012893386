import React, {memo, useMemo} from 'react';
import { useRouter } from 'next/router'

import Avatar from 'components/Avatar';
import Dropdown from '@turingshequ/dropdown';
import RightButton from '../rightbutton';
import ArrowRight from 'components/ArrowRight';

import { NotificationPagePath } from 'pagerouter';
import {MessageListItem} from 'fetch';
import MessageDesc, {needLink2Person, Link2Personal} from 'components/MessageCard/MessageDesc'
import s from '../index.module.scss';

const formatTime = (time: string) => time.replace(/\//g, '-')
interface Props {
  messageCount: number
  messageList: MessageListItem[]
  dropdownCount: number
}
const UnReadNum: React.FC<Props> = ({
  messageCount,
  messageList,
  dropdownCount
}) => {

  const router = useRouter()


  const messagesDropList = useMemo(() => {
    return messageList.slice(0, dropdownCount).map((item) => {
      return <div className={s['notify-item-container']} key={item.id}>
        <div className={s['notify-item-avatar']}>
          <Avatar url={item.actionUserImage} size={28} dot={!item.haveRead} />
        </div>
        <div className={s.right}>
          <div className={s.line}>
            <div className={s['author-name']}>
              {item.actionUserName}
            </div>
            <div className={s.date}>
              {formatTime(item.createTimeStr)}
            </div>
          </div>
          <div className={s.message}>
            <MessageDesc type={item.type}>
            <>
              {
                needLink2Person(item.type)
                ? <Link2Personal id={item.reportedUserId!} name={item.reportedUserName!} />
                : <>《{item.worksTitle || '未命名'}》</>
              }
            </>
            </MessageDesc>
          </div>
        </div>
      </div>
    })
  }, [messageList, dropdownCount])



  return (<Dropdown main={(api) => {
    const fold = api.getFold()
    return <div className={`${s['align-center']} ${!fold ? `${s['hightlight']}` : ''}`}>
      <RightButton text='' icon='noti' count={messageCount} onClick={() => {
        router.push({
          pathname: NotificationPagePath,
        })
      }} />
    </div>
  }} content={
    messagesDropList.length ? <div className={s['notice-dropdown']}>
      {messagesDropList}
      <div className={s.viewall} onClick={() => {
        router.push({
          pathname: NotificationPagePath,
        })
      }}><ArrowRight/></div>
    </div> : null
  } />)
}
export default memo(UnReadNum);
