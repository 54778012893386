import React, {useCallback, MouseEvent} from 'react';
import Avatar from 'components/Avatar'
import Dropdown from '@turingshequ/dropdown'
import { useRouter } from 'next/router'
import redirectLogin from 'fetch/redirectLogin';
import {logoutReq, UserData} from 'fetch';
import { PersonalCenter, WorksManage } from 'pagerouter';

import s from '../index.module.scss';


interface Props {
  user: UserData | null | undefined
  isYdCourseAppWebView: boolean
}
  
const LoginOrAvatar: React.FC<Props> = ({
  user,
  isYdCourseAppWebView
}) => {

  const router = useRouter()

  const onLogout = useCallback((e: MouseEvent) => {
    e.stopPropagation()
    logoutReq()
  }, [])

  const go2Page = (e: MouseEvent, routerPath: string) => {
    e.stopPropagation();
    router.push(routerPath)
  }

  if (user === null) {
    return <div className={`${s['align-center']} ${s['login']}`} onClick={() => {
      redirectLogin()
    }}>登录/注册</div>
  }

  /** 精品课APP 内 不展示退出登录的按钮 点击头像直接跳转 个人中心 */
  if(user && isYdCourseAppWebView) {
    return <div className={`${s['align-center']} ${s['avatar']}`} onClick={e => go2Page(e, PersonalCenter)}>
    <Avatar url={user.userImage} size={36} />
  </div>
  }

  if (user && !isYdCourseAppWebView) {
    return <Dropdown
      main={
        (api) => {
          const fold = api.getFold()
          return <div className={`${s['align-center']} ${!fold ? s['hightlight'] : ''} ${s['avatar']}`}>
            <Avatar url={user.userImage} size={36} />
          </div>
        }
      }
      content={
        (
          <>
            <div className={s['logout-box']} onClick={e => go2Page(e, PersonalCenter)}>个人主页</div>
            <div className={s['logout-box']} onClick={e => go2Page(e, WorksManage)}>作品管理</div>
            <div className={s['logout-box']} onClick={onLogout}>退出登录</div>
          </>
        )
      }
    />
  }

  return null;
}
export default LoginOrAvatar;