import React from 'react';
import { PersonalCenter } from 'pagerouter';
import {MsgType, MSG_TEXT_MAP} from './index';

interface Props {
    type: MsgType
    children: JSX.Element
}


export const needLink2Person = (type: MsgType) => {
  return [
    MsgType.USER_REPORT,
    MsgType.USER_REPORT_HANDLE
  ].includes(type)
}

interface Link2PersonalProps {
  id: string
  name: string
}

export const Link2Personal = ({id, name}: Link2PersonalProps) => (
  <a target="_blank" rel="noreferrer" href={PersonalCenter + '?userId=' + btoa(id)}>{name}</a>
)


const MessageDesc:React.FC<Props> = ({
    type,
    children
}) => {

    if([
        MsgType.LICKED,
        MsgType.SAVED,
        MsgType.COMMENT,
        MsgType.REPLAY,
    ].includes(type)) {
        return (<>{MSG_TEXT_MAP[type]}了你的作品{children}</>)
    }

    if([
        MsgType.WARNING,
        MsgType.OFF,
    ].includes(type)) {
        return (<>{MSG_TEXT_MAP[type]}你的作品{children}</>)
    }

    const messageMap = {
      [MsgType.REPORT_SUCCESS]: <>【收到了】你对作品{children}的举报</>,
      [MsgType.OFF_SUCCESS]: <>【下架了】你举报的作品{children}</>,
      [MsgType.REPORT_CR_SUCCESS]: <>【收到了】你对以下评论的举报，小图灵已启动审查机制，感谢你的正义举报</>,
      [MsgType.REPORT_HANDLE]: <>【处理了】你对以下评论的举报,已做删除处理，情节严重者可能也会采取禁言，感谢你对创作社区的正义维护</>,
      [MsgType.DELETE_COMMENT]: <>【删除了】你的以下评论及其子回复，请谨慎发言，多次违规发言将会被禁言</>,
      [MsgType.USER_REPORT]: <>【收到了】你对{children}的举报</>,
      [MsgType.USER_REPORT_HANDLE]: <>【处理了】你对{children}的举报</>,
      [MsgType.MUTE]: <>【封禁】了你的账号</>,
      [MsgType.MUTE_OFF]: <>【解除了】你的禁言</>,
    } as any;

    return (messageMap[type]) || null;
}

export default MessageDesc;
