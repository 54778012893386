import Link, { LinkProps } from 'next/link'
import React, { CSSProperties, memo } from 'react'
/**@ts-ignore */
import s from './index.module.sass'

function Logo({ style, colored, ...linkProps }: React.PropsWithChildren<LinkProps> & { style?: CSSProperties, colored?: boolean }) {
  return <Link {...linkProps}>
    <div className={s.logo + ' logo' + (colored ? ' ' + s.colored : '')} style={style}></div>
  </Link>
}

export default memo(Logo)