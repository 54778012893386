import React, { ReactElement } from 'react';


interface Props {
  show: boolean,
  children: ReactElement
}
  
const IfRender:React.FC<Props> = ({
  show = true,
  children
}) => {
  return (<>{show && children}</>)
}
export default IfRender;