import React, {ReactElement, memo} from 'react';
import { useRouter } from 'next/router'

import {PersonalCenter} from 'pagerouter';

interface Props {
  children: ReactElement | string
  id: string | undefined
}
const Go2Personal: React.FC<Props> = ({
  children,
  id
}) => {
  const router = useRouter();

  const click = () => {
    if(!id) {
      return;
    }
    router.push({
      pathname: PersonalCenter,
      query: {
        userId: btoa(id)
      }
    })
  }
  return (<span
    style={{cursor: 'pointer'}}
    onClick={click}
  >
    {children}
  </span>)
  }
export default memo(Go2Personal);
