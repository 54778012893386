
import React, { useEffect, useState } from 'react';

import IfRender from 'components/IfRender';

import { iconEmoji } from 'assets';

import EMOJI_LIST from './list.json';
import EMOJI_MAP from './map.json';
import s from './index.module.scss';

export interface EmojiItem {
    num: string
    text: string
    url: string
}

interface Props {
    onClick: (url: string) => void;
}

const Emoji:React.FC<Props> = ({
    onClick
}) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        document.querySelector('body')?.addEventListener('click', handleBodyClick)
        return () => {
            document.querySelector('body')?.removeEventListener('click', handleBodyClick)
        }
    }, [])

    return (
        <div className={s.wrap}>
            <span onClick={handleShowClick} className={s.handle}><img src={iconEmoji} alt="" /> 表情</span>
            <IfRender show={show}>
                <div className={s.emojiPopWrap}>
                    <div className={s.emojiWrap}>
                        <div className={s.title}>全部表情</div>
                        <div className={s.emojiList}>
                            {
                                EMOJI_LIST.map(item => (
                                    <span 
                                        className={s.item} 
                                        key={item.num} 
                                        title={item.text} 
                                        onClick={() => handleClick(item)}
                                    >
                                        <img src={item.url} alt={item.text} />
                                    </span>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </IfRender>
            
        </div>
    )
    function handleClick(item: EmojiItem) {
        onClick && onClick(item.text)
        setShow(false);
    }

    function handleShowClick(e: any) {
        e.stopPropagation();
        setShow(true);
    }

    function handleBodyClick() {
        setShow(false);
    }
}

export default Emoji;

export const changeEmojiDesc2Img = (content: string) => {
    if(!content) return content;
    const emotionReg = /\[[^\\[\]]+?\]/g;
    const matchResult = content.match(emotionReg);
    if(matchResult) {
        matchResult.forEach((key) => {
            const emojiTest = key as (keyof typeof EMOJI_MAP);
            if (EMOJI_MAP[emojiTest]) {
                content = content.replace(
                    key,
                  `<img src="${EMOJI_MAP[emojiTest]}">`
                ).replace('\n', '<br />');
              }
        })
    }
    return content;
}