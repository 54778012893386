import React, {
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";


const dropdownContext = React.createContext<IDropdownAPI | void>(undefined);

export interface IDropdownProps<
  C extends ComponentArgType = ComponentArgType,
  M extends ComponentArgType = ComponentArgType
> {
  main: M;
  content: C;
  position?: string;
  hover?: boolean;
  onFold?: (fold: boolean) => void;
  disabled?: boolean
}

export interface IDropdownAPI {
  fold(): void;
  unfold(): void;
  getFold(): boolean;
}

type ComponentArgType = React.ReactNode | React.ComponentType<IDropdownAPI>;

function HoverDropdown(props: IHoverDropdownProps) {
  const { disabled } = props;
  const api = useContext(dropdownContext);
  if (!api) {
    throw new Error("Bad Dropdown Uses.");
  }
  const fold = api.getFold();
  const onMouseEnter = useCallback(
    () => {
      if (fold) {
        api.unfold();
      }
    },
    [api, fold]
  );
  const onMouseLeave = useCallback(
    () => {
      if (!fold) {
        api.fold();
      }
    },
    [api, fold]
  );
  return (
    <div
      className="icode-component-dropdown-cont"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onTouchStart={onMouseEnter}
    >
      <div className="icode-component-dropdown-main">{props.main}</div>
      <div
        className="icode-component-dropdown-content-cont"
        style={{ display: (fold || disabled) ? "none" : undefined }}
      >
        {/* hover弹窗的位置，偏左or偏右，默认偏右 */}
        <div className={`icode-component-dropdown-content ${props.position === 'left' ? 'icdc-left' : 'icdc-right'}`}>{props.content}</div>
      </div>
    </div>
  );
}

function Dropdown(props: IDropdownProps) {
  const { disabled } = props;
  const [fold, setFold] = useState(true);

  const api: IDropdownAPI = useMemo(() => {
    return {
      fold: () => {
        setFold(true);
      },
      unfold: () => {
        setFold(false);
      },
      getFold: () => {
        return fold;
      }
    };
  }, [fold]);

  const initiateChild = useCallback(
    (factory: ComponentArgType | React.ReactNode) => {
      let child: React.ReactNode;
      if (typeof factory === "function") {
        child = React.createElement(
          factory as React.ComponentType<IDropdownAPI>,
          api
        );
      } else {
        child = factory;
      }
      return child;
    },
    [api]
  );

  const main = useMemo(() => initiateChild(props.main), [
    props.main,
    initiateChild
  ]);

  const content = useMemo(() => initiateChild(props.content), [
    props.content,
    initiateChild
  ]);

  return (
    <dropdownContext.Provider value={api}>
      <HoverDropdown disabled={disabled} main={main} content={content} position={props.position}/>
    </dropdownContext.Provider>
  );
}

interface IHoverDropdownProps {
  main: React.ReactNode;
  content: React.ReactNode;
  position?: string;
  disabled?: boolean
}

export default Dropdown;
