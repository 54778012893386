function resolve(path: string) {
  return process.env.NEXT_PUBLIC_BASE_PATH + (path || '')
}

export const HomePagePath = resolve('')
export const DiscoveryPagePath = resolve('discovery')
export const WorkspacePagePath = resolve('workspace/py')
export const NotificationPagePath = resolve('notification')
export const PreviewPagePath = resolve('work/')
export const GuidePage = resolve('community_guide')
export const PersonalCenter = resolve('personal')
export const WorksManage = resolve('my/works')

