import React from 'react';
import { arrowSvg } from 'assets';

const style = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#22A0FC',
  fontSize: '14px'
}

interface Props {
  text?: string
}
  
const ArrowRight: React.FC<Props> = ({
  text = '查看全部'
}) => {
  return (
    <div style={style}>{text}<img src={arrowSvg} alt=""></img></div>
  )
}
export default React.memo(ArrowRight);