import React, { memo, useContext, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'

import Dropdown from '@turingshequ/dropdown'
import { UserContext } from 'components/UserProvider'
import { YDLog } from 'components/YDLog'
import Logo from '../Logo'
import Button from './button'
import IfRender from 'components/IfRender';
import UnReadNum from './UnReadNum';
import LoginOrAvatar from './LoginOrAvatar';

import {
  HomePagePath,
  DiscoveryPagePath,
  WorkspacePagePath,
} from 'pagerouter'

import { getUnreadDetail, MessageListItem } from 'fetch'
import { KE_TURING_TAG, ICODE_CONTEST, SCRATCH_HOST, LOG } from 'util/constants'
import { useYdCourseApp } from 'hooks/useYdCourseApp'
import { useTuringApp } from 'hooks/useTuringApp'

import s from './index.module.scss'
export interface HeaderProps {
  noBackground?: boolean,
  logoColored?: boolean
}

const FROM_KEY_STRING = 'icodeshequ_nav'
const FROM_STRING = LOG.visit_ide[FROM_KEY_STRING]

export default memo(function Header(props: HeaderProps) {
  const router = useRouter()
  const [messageCount, setMessageCount] = useState<number>(0)
  const [messageList, setMessageList] = useState<MessageListItem[]>([])
  const initCountRef = useRef(0)
  const { user, updateUnread, dispatch } = useContext(UserContext)
  const { isYdCourseAppWebView } = useYdCourseApp(user)
  const { isTuringAppWebView } = useTuringApp()


  useEffect(() => {
    if (user) {
      getUnreadDetail().then(ret => {
        initCountRef.current = ret.data.count
        setMessageCount(ret.data.count)
        setMessageList(ret.data.messageList || [])
      })
    }
  }, [user, updateUnread])

  const dropdownCount = Math.min(5, initCountRef.current)

  // 创作的下拉菜单
  const renderWorkContent = () => (
    <div className={s.workSelect}>
      <YDLog
        click={{ name: 'click', data: { button_name: '创作', from: FROM_STRING, create_type: 'blockly' } }}
      >
        <div className={s.work} onClick={() => dispatch({ type: 'showThemeModal', from: FROM_KEY_STRING })}>
          Blockly图形化创作
        </div>
      </YDLog>
      <YDLog
        click={{ name: 'click', data: { button_name: '创作', from: FROM_STRING, create_type: 'scratch' } }}
      >
        <Link href={`${SCRATCH_HOST}?from=${FROM_KEY_STRING}`}>
          <div className={s.work}>图形化创作</div>
        </Link>
      </YDLog>
      <YDLog 
        click={{ name: 'click', data: { button_name: '创作', from: FROM_STRING, create_type: 'python' } }}
      >
        <Link href={`${WorkspacePagePath}?from=${FROM_KEY_STRING}`}>
          <div className={s.work}>Python创作</div>
        </Link>
      </YDLog>
    </div>
  )

  return <div className={props.noBackground ? '' : s['header-bg'] + ' full-width'}>
    <div className={`restrict-width ${s.header}`}>
      {/* logo */}
      <div className={s['logo-ct']}>
        <Logo href='/' style={{
          width: '214px',
          height: '60px',
        }} colored={props.logoColored} />
      </div>
      {/* 导航tab */}
      <div className={s['flex-grow']}>
        <Button text='首页' active={router.pathname === HomePagePath} style={{ marginLeft: 0 }} onClick={() => {
          router.push(HomePagePath)
        }} />
        {!isYdCourseAppWebView && !isTuringAppWebView && (
          <Button text='课程' link={KE_TURING_TAG} />
        )}
        <Button text='发现' active={router.pathname === DiscoveryPagePath} onClick={() => {
          router.push(DiscoveryPagePath)
        }} />
        <IfRender show={!isYdCourseAppWebView && !isTuringAppWebView}>
          <Button text='信息学专区' link={ICODE_CONTEST} style={{ marginRight: 0 }} />
        </IfRender>
      </div>
      {/* 操作按钮 */}
      <div className={s['header-right-btns']}>
        {/* 未读消息 */}
        <IfRender show={!!user}>
          <UnReadNum 
            messageCount={messageCount}
            messageList={messageList}
            dropdownCount={dropdownCount}
          />
        </IfRender>
        {/* 登录 、头像 */}
        <LoginOrAvatar user={user} isYdCourseAppWebView={!!isYdCourseAppWebView}/>
        {/* 创作按钮 */}
        <Dropdown
          position='right'
          main={(api) => {
            const fold = api.getFold()
            return (
              <div className={`${s.workBtn} ${!fold ? `${s.hightlight}` : ''}`}>
                <span>创作</span>
              </div>
            )
          }}
          content={renderWorkContent()}
        />
      </div>
    </div>
  </div>
})