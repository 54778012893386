import React, { memo } from 'react'
/** @ts-ignore */
import s from './button.module.css'

export interface ButtonProps {
  active?: boolean;
  text: string;
  link?: string;
  style?: any;
  onClick?: React.MouseEventHandler
}

function Button({ text, active, link, ...props }: ButtonProps) {
  if(link) {
    return <a className={`${s.headerBtn} ${s.aBtn}`} href={link} target="_blank" rel="noreferrer" {...props}>{text}</a>
  }
  return <div className={`${s.headerBtn} ${active ? s.active : ''}`} {...props}>{text}</div>
}

export default memo(Button)
