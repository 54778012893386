function resolve(path: string) {
  return process.env.NEXT_PUBLIC_CDN_HOST + path
}

export const qrcodeSvg = resolve('qrcode.svg')
export const avatarJpeg = resolve('avatar.jpeg')
export const bannerPng = resolve('banner.png')
export const guideSvg = resolve('guide.svg')
export const exitFullScreenSVG = resolve('exitfullscreen.svg')
export const enterFullScreenSVG = resolve('enterfullscreen.svg')
export const meshSVG = resolve('mesh.svg')
export const pythonGuide = resolve('pythonguide.svg')
export const loadmorebtnSVG = resolve('loadmorebtn.svg')
export const releasearrow = resolve('releasearrow.svg')
export const playbtn = resolve('playbtn.svg')
export const top1Badge = resolve('top1.svg')
export const top2Badge = resolve('top2.svg')
export const top3Badge = resolve('top3.svg')
export const blocklyBadge = resolve('home/icon_blockly.png')
export const scratchBadge = resolve('home/icon_scratch.png')
export const pythonBadge = resolve('home/icon_python.png')
export const arrowSvg = resolve('arrow.svg')
export const polyIcon = resolve('PolyIcon.svg')

export const leftIcon = resolve('icon-left.png')
export const rightIcon = resolve('icon-right.png')
export const personalBannerBg = resolve('personal-banner-bg.png')

// 个人中心

export const worksIcon = resolve('personal/works.svg')
export const viewIcon = resolve('personal/view.svg')
export const likedIcon = resolve('personal/liked.svg')
export const saveIcon = resolve('personal/saved.svg')
export const forkIcon = resolve('personal/fork.svg')
export const editIcon = resolve('personal/icon-edit.svg')

// 作品详情页

export const iconCommit = resolve('work/icon-replay.svg')
export const iconDelete = resolve('work/icon-delete.svg')
export const iconEdit = resolve('work/fork.svg')
export const iconLiked = resolve('work/liked.svg')
export const iconSave = resolve('work/saved.svg')
export const iconEmoji = resolve('work/default_emoji.svg')
export const iconThumbsubLight = resolve('work/liked-active.svg')
export const iconThumbsub = resolve('thumbsup.svg')
export const defaultAvatar = resolve('default_avatar.png')
export const iconTriangle = resolve('work/triangle.svg')

// 首页

export const rank1 = resolve('home/rank1.svg')
export const rank2 = resolve('home/rank2.svg')
export const rank3 = resolve('home/rank3.svg')
export const rank4 = resolve('home/rank4.svg')
export const rank5 = resolve('home/rank5.svg')


export const iconCheck = resolve('common/icon-check.svg')
export const iconCheckSuccess = resolve('common/icon-check-success.svg')

export const iconUpload = resolve('python/icon-upload.svg');
