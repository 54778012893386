import { useEffect, useState } from 'react';
import { isTuringApp } from '../util'

export function useTuringApp() {
  const [isTuringAppWebView, setIsTuringAppWebView] = useState<boolean|undefined>();

  useEffect(() => {
    const isYouDaoCourseApp = isTuringApp();
    setIsTuringAppWebView(isYouDaoCourseApp);
  }, []);

  return {
    isTuringAppWebView,
  };
}
