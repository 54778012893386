import React, { memo, useMemo } from 'react'
/** @ts-ignore */
import s from './rightbutton.module.sass'

export interface RightButtonProps {
  icon?: string
  text: string
  count?: number | string,
  onClick?: React.MouseEventHandler
}

function handleCount(count: number | string) {
  return  Number(count) > 998 
    ? '...'
    : count
}

function RightBtn(props: RightButtonProps) {
  const count = useMemo(() => {
    return props.count != null && props.count !== 0 ? <div className={s['count']}>{handleCount(props.count)}</div> : null
  }, [props.count])
  const icon = useMemo(() => {
    if (props.icon) {
      return <div className={`${s['right-btn-icon']} ${s[props.icon]}`}>
        {count}
      </div>
    }
    return count
  }, [props.icon, count])
  return <div className={s['right-btn']} onClick={props.onClick}>
    {icon}
    {props.text}
  </div>
}

export default memo(RightBtn)